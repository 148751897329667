var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orders"},[_c('div',{staticClass:"card_hor card round actions"},[_c('header',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.$t('Orders.货运流程')))]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'PackList',
            query: { status: 'outstock', type: 'package' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.pkg_OutStock),expression:"stats.pkg_OutStock"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.pkg_OutStock)+" ")]),_c('div',{staticClass:"icon warehousing"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.预报包裹')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'PackList',
            query: { status: 0, type: 'package' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.pkg_InStock),expression:"stats.pkg_InStock"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.pkg_InStock)+" ")]),_c('div',{staticClass:"icon warehoused"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.9mbfgh')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'OrderListProcessing',
            query: { status: -100, type: 'order' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.od_Zhuanyun),expression:"stats.od_Zhuanyun"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.od_Zhuanyun)+" ")]),_c('div',{staticClass:"icon load"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.处理中')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'OrderListShipping',
            query: { status: -101, type: 'order' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.od_Shipping),expression:"stats.od_Shipping"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.od_Shipping)+" ")]),_c('div',{staticClass:"icon on-the-way"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.z9yr5v')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'OrderListChecked',
            query: { status: -20, type: 'order' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.od_Qianshou),expression:"stats.od_Qianshou"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.od_Qianshou)+" ")]),_c('div',{staticClass:"icon order-checked"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.f7pf4j')))])])])]),_c('div',{staticClass:"card round"},[_c('header',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.$t('Orders.集运工具')))]),_c('div',{staticClass:"grid"},[_c('router-link',{staticClass:"cell",attrs:{"tag":"div","to":{ name: 'EnterPackage' }}},[_c('div',{staticClass:"icon enter"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.pg4hvd')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'PackList',
            query: { status: -10, type: 'package' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.pkg_Total),expression:"stats.pkg_Total"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.pkg_Total)+" ")]),_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{staticStyle:{"color":"rgb(156 155 182)"},attrs:{"icon":"box","size":"2x"}})],1),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.全部包裹')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'PackList',
            query: { status: 2, type: 'package' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.pkg_Lost),expression:"stats.pkg_Lost"}],staticClass:"badge"},[_vm._v(_vm._s(_vm.stats.pkg_Lost))]),_c('div',{staticClass:"icon claim"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.cbi5ag')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'PackList',
            query: { status: 3, type: 'package' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.pkg_Problem),expression:"stats.pkg_Problem"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.pkg_Problem)+" ")]),_c('div',{staticClass:"icon problem-package"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.yrfvav')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'RecStorageAddr',
          })}}},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{staticStyle:{"color":"rgb(156 155 182)"},attrs:{"icon":"map-marker","size":"2x"}})],1),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.收货仓库')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'AddressList',
          })}}},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{staticStyle:{"color":"rgb(156 155 182)"},attrs:{"icon":"address-book","size":"2x"}})],1),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.客户地址')))])])],1)]),_c('div',{staticClass:"card round hidden"},[_c('header',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.$t('Orders.bmz3lu')))]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'OrderList',
            query: { status: -10, type: 'order' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.od_Total),expression:"stats.od_Total"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.od_Total)+" ")]),_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{staticStyle:{"color":"rgb(156 155 182)"},attrs:{"icon":"database","size":"2x"}})],1),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.全部订单')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'OrderList',
            query: { status: -12, type: 'order' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.od_Cancel),expression:"stats.od_Cancel"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.od_Cancel)+" ")]),_c('div',{staticClass:"icon canceled"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.eif2md')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'OrderList',
            query: { status: -3, type: 'order' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.od_ErropExp),expression:"stats.od_ErropExp"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.od_ErropExp)+" ")]),_c('div',{staticClass:"icon problem-order"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.30go9b')))])]),_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({
            name: 'OrderList',
            query: { status: -11, type: 'order' },
          })}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stats.od_Tuihuo),expression:"stats.od_Tuihuo"}],staticClass:"badge"},[_vm._v(" "+_vm._s(_vm.stats.od_Tuihuo)+" ")]),_c('font-awesome-icon',{staticClass:"icon-primary",attrs:{"icon":"box-tissue","size":"2x"}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.退货单')))])],1)])]),_c('div',{staticClass:"card round hidden"},[_c('header',{staticClass:"card-header"},[_vm._v(_vm._s(_vm.$t('Orders.wwampb')))]),_c('div',{staticClass:"grid"},[_c('div',{staticClass:"cell",on:{"click":function($event){return _vm.$router.push({ name: 'OrderQuery', query: { status: 1 } })}}},[_c('div',{staticClass:"icon package"}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.$t('Orders.vthnqw')))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }